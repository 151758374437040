class ValidationError extends Error {
  constructor(title, details) {
    super('VALIDATION_ERROR');
    this.errorCode = 'VALIDATION_ERROR';
    this.details = details;
    this.title = title;
  }
}

export default ValidationError;
