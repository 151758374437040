class SchemaValidationError extends Error {
  constructor(hash, details) {
    super(SchemaValidationError.errorCode);
    this.errorCode = 'SCHEMA_ERROR';
    this.details = details;
    this.hash = hash;
  }
}

export default SchemaValidationError;
